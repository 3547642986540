import "@google/model-viewer";

import HomeScreen from "./Screens/HomeScreen";
import ProductScreen from "./Screens/ProductScreen.js";
import CartScreen from "./Screens/CartScreen";
import ShippingScreen from "./Screens/ShippingScreen";

import OrderSummaryScreen from "./Screens/OrderSummaryScreen";
import OrderConfirmScreen from "./Screens/OrderConfirmScreen";
import TestRideScreen from "./Screens/TestRideScreen";
import TestRideConfirmScreen from "./Screens/TestRideConfirmScreen";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import AboutScreen from "./Screens/AboutScreen";
import ContactUsScreen from "./Screens/ContactUsScreen";
import EMIScreen from "./Screens/EMIScreen";
import PartnerScreen from "./Screens/PartnerScreen";
import WarrantyScreen from "./Screens/WarrantyScreen";
import InsuranceScreen from "./Screens/InsuranceScreen";
import ProfileScreen from "./Screens/ProfileScreen";
import MyOrdersScreen from "./Screens/MyOrdersScreen";
import MyOrderDetails from "./Screens/MyOrderDetails";
import ThankYouPage from "./Screens/ThankYouPage";
import CommunityScreen from "./Screens/CommunityScreen";
import TermsAndCondition from "./Screens/TermsAndCondition";
import CookiePolicy from "./Screens/CookiePolicy";
import PrivacyPolicyScreen from "./Screens/PrivacyPolicyScreen";
import BlogsScreen from "./Screens/BlogsScreen";
import BlogScreen from "./Screens/BlogScreen";
import TrackingScreen from "./Screens/TrackingScreen";
import AccessoriesScreen from "./Screens/AccessoriesScreen";
import AccessoriesCategoryScreen from "./Screens/AccessoriesCategoryScreen";
import AllAccessoriesScreen from "./Screens/AllAccessoriesScreen";
import ReturnPolicyScreen from "./Screens/ReturnPolicyScreen";
import NewsScreen from "./Screens/NewsScreen";
import DigitalMediaScreen from "./Screens/DigitalMediaScreen";
import AllBikesScreen from "./Screens/AllBikesScreen";
import RangeBikesScreen from "./Screens/RangeBikesScreen";
import LandingPage2Screen from "./Screens/LandingPage2Screen";
import DealersScreen from "./Screens/DealersScreen";
import StoreScreen from "./Screens/StoreScreen";
import LandingPage3Screen from "./Screens/LandingPage3Screen";
import StudentLandingPageScreen from "./Screens/StudentLandingPageScreen";
import LandingSubmit from "./Screens/LandingSubmit";
import "@splidejs/splide/dist/css/splide.min.css";
import WaitlistLandigPage from "./Screens/WaitlistLandigPage";
import ReactGAA from "react-ga4";
import ReactGA from "react-ga";
import FindStoreScreen from "./Screens/FindStoreScreen";
import MovementsLandingPage from "./Screens/MovementsLandingPage";
import LeadSubmitScreen from "./Screens/LeadSubmitScreen";
import DoodleLandingPage from "./Screens/DoodleLandingPage";
import CheckInScreen from "./Screens/CheckInScreen";
import CheckInSuccessScreen from "./Screens/CheckInSuccessScreen";
import QuizScreen from "./Screens/QuizScreen";
import LandingTestScreen from "./Screens/LandingTestScreen";
import PapScreen from "./Screens/PapScreen";
import NotifyMe from "./Components/NotifyMe";
import PreBookScreen from "./Screens/PreBookScreen";
import PreBuyScreen from "./Screens/PreBuyScreen";
import { CorporateGiftingLandingPage } from "./Screens/CorporateGiftingLandingPage";
import { useEffect } from "react";
import PreOrderThankyouScreen from "./Screens/PreOrderThankyouScreen";
import AppPrivacyPolicy from "./Screens/AppPrivacyPolicy";
import AppTermsAndConditions from "./Screens/AppTermsAndConditions";
import PaymentFailedScreen from "./Screens/PaymentFailedScreen";
import SurveyForm from "./Screens/SurveyForm";
import KnowYourCycle from "./Screens/KnowYourCycle";
import UserManual from "./Components/UserManual";
import TRexPreBook from "./Screens/TRexPreBook";
import CycleVsEcycle from "./Screens/CycleVsEcycle";
import CycleVsEcycleKerala from "./Screens/CycleVsEcycleKerala";
import FormulaFunCategoryScreen from "./Screens/FormulaFunCategoryScreen";
import ToyProductScreen from "./Screens/ToyProductScreen";
import SpinWheel from "./Screens/SpinWheel";
import AccessoryScreen from "./Screens/AccessoryScreen.js";
import AllAccessoryScreen from "./Screens/AllAccessoryScreen.js";
import XcapeScreen from "./Screens/XcapeScreen.js";
import BookUnitsScreen from "./Screens/BookUnitsScreen.js";
import PreBookDoneScreen from "./Screens/PreBookDoneScreen.js";
import TestRideDuplicate from "./Screens/TestRideDuplicate.js";
import LandingPageScreen from "./Screens/LandingPageScreen.js";
import GltfTrialScreen from "./Screens/TRexFamilyScreen.js";
import TRexFamilyScreen from "./Screens/TRexFamilyScreen.js";
import TRexProFamilyScreen from "./Screens/TRexProFamilyScreen.js";
import { TRexAirScreen } from "./Screens/TRexAirScreen.js";
import TRexV3Screen from "./Screens/TRexV3Screen.js";
import FormulaFunHomeScreen from "./Screens/FormulaFunHomeScreen.js";
import ScrollToTop from "./Components/ScrollToTop.js";
import ServiceQueryScreen from "./Screens/ServiceQueryScreen.js";

ReactGAA.initialize("G-4CJDDN3E7Q");
ReactGA.initialize("UA-180929846-1");

function App() {
  const AccessoriesOrBeaconScreen = () => {
    const { productName } = useParams();

    if (
      productName.toLowerCase() === "beacon" ||
      productName.toLowerCase() === "miniair" ||
      productName.toLowerCase() === "blazebeam" ||
      productName.toLowerCase() === "foamlite" ||
      productName.toLowerCase() === "metal-fender" ||
      productName.toLowerCase() === "plastic-fender" ||
      productName.toLowerCase() === "carrier" ||
      productName.toLowerCase() === "fortknox-lock" ||
      productName.toLowerCase() === "miniair-jet" ||
      productName.toLowerCase() === "airwave" ||
      productName.toLowerCase() === "airflow" ||
      productName.toLowerCase() === "radlite" ||
      productName.toLowerCase() === "nexus" ||
      productName.toLowerCase() === "firefly" ||
      productName.toLowerCase() === "aeroshield" ||
      productName.toLowerCase() === "lumo" ||
      productName.toLowerCase() === "flash-trail" ||
      productName.toLowerCase() === "fortknox-lite" ||
      productName.toLowerCase() === "fortknox-plus" ||
      productName.toLowerCase() === "keyguard" ||
      productName.toLowerCase() === "hydro-smart" ||
      productName.toLowerCase() === "nrg-pro"
    ) {
      return <AccessoryScreen />;
    }

    // Otherwise, render the AccessoriesScreen
    return <AccessoriesScreen />;
  };

  function AmazonX1Redirect() {
    useEffect(() => {
      window.location.href =
        "https://www.amazon.in/EMotorad-Mountain-Removable-Suspension-Bumblebee/dp/B0BSLLFC1T";
    }, []);

    return null;
  }
  function AmazonEMXPlusRedirect() {
    useEffect(() => {
      window.location.href =
        "https://www.amazon.in/EMotorad-Electric-Shimano-Aluminium-Suspension/dp/B0CK4MMCDW?th=1";
    }, []);

    return null;
  }

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/corporate-gifts"
            element={<CorporateGiftingLandingPage />}
          />
          <Route path="/t-rex-family" element={<TRexFamilyScreen />} />
          <Route
            path="/t-rex-family/t-rex-pro"
            element={<TRexProFamilyScreen />}
          />
          <Route path="/t-rex-family/t-rex-air" element={<TRexAirScreen />} />
          <Route path="/t-rex-family/t-rex-v3" element={<TRexV3Screen />} />

          {/* <Route path="/t-rex-pro/prebook" element={<BookUnitsScreen />} />
          {/* <Route path="/prebook-confirmed" element={<PreBookDoneScreen />} /> */}
          {/* <Route path="/nps-survey" element={<SurveyForm />} /> */}
          {/* <Route path="/acessories" element={<AllAccessoryScreen />} /> */}
          {/* <Route path="/acessories/:name" element={<AccessoryScreen />} /> */}
          <Route path="/spin-the-wheel" element={<SpinWheel />} />
          {/* <Route path="/formula-fun" element={<FormulaFunHomeScreen />} />
          <Route
            path="/formula-fun/all-products"
            element={<FormulaFunCategoryScreen />}
          /> */}
          {/* <Route path="/formula-fun" element={<FormulaFunCategoryScreen />} /> */}
          <Route path="/amazon/x1" element={<AmazonX1Redirect />} />
          <Route path="/know-your-ecycle" element={<KnowYourCycle />} />
          <Route path="/know-your-ecycle/:name" element={<UserManual />} />
          {/* <Route path="/prebook-msd-blue" element={<WaitlistLandigPage />} /> */}
          <Route path="/cycle-vs-ecycle" element={<CycleVsEcycle />} />
          <Route
            path="/cycle-vs-ecycle/kerala"
            element={<CycleVsEcycleKerala />}
          />
          <Route path="/amazon/emx+" element={<AmazonEMXPlusRedirect />} />
          {/* <Route path="/notify" element={<PreBookScreen />} /> */}
          <Route path="/quiz" element={<QuizScreen />} />
          <Route path="/lead-submitted" element={<LandingSubmit />} />
          <Route path="/buy-now" element={<LandingPage3Screen />} />
          <Route path="/book-now" element={<LandingTestScreen />} />
          {/* <Route path="/student" element={<LandingPageScreen />} /> */}
          <Route
            path="/mahendra-singh-dhoni-electric-cycles"
            element={<PapScreen />}
          />
          {/* <Route path="/newlaunch-doodle" element={<DoodleLandingPage />} /> */}
          <Route path="/movements-of-fun" element={<MovementsLandingPage />} />
          <Route path="/check-in" element={<CheckInScreen />} />
          <Route path="/bikes" index element={<AllBikesScreen />} />
          <Route path="/find" element={<StoreScreen />} />
          <Route path="/find-e-bicycle-store" element={<FindStoreScreen />} />
          <Route path="/" index element={<HomeScreen />} />
          <Route path="/store-finder" index element={<DealersScreen />} />
          <Route path="/ourstory" element={<AboutScreen />} />
          <Route path="/bikes/:name" element={<ProductScreen />} />
          <Route path="/bikes/range/:name" element={<RangeBikesScreen />} />
          <Route
            path="/accessories/category/:name"
            element={<AccessoriesCategoryScreen />}
          />
          <Route
            path="/accessories/:productName"
            element={<AccessoriesOrBeaconScreen />}
          />
          <Route
            path="/accessories/xcape/:productName"
            element={<AccessoryScreen />}
          />
          <Route path="/blogs" element={<BlogsScreen />} />
          <Route path="/media-kit" element={<DigitalMediaScreen />} />
          <Route path="/blogs/:slug" element={<BlogScreen />} />
          {/* {posts.map((post, i) => (
            <Route
              key={i}
              path={`/blogs/${post.id}`}
              element={<Navigate to={`/blogs/${post.slug}`} />}
            />
          ))} */}
          {/* {posts.map((post, i) => (
            <Route
              key={i}
              path={`/blog/${post.id}`}
              element={<Navigate to={`/blogs/${post.slug}`} />}
            />
          ))} */}
          <Route path="/news" element={<NewsScreen />} />
          <Route path="/contact-us" element={<ContactUsScreen />} />
          <Route path="/warranty" element={<WarrantyScreen />} />
          <Route path="/emi" element={<EMIScreen />} />
          <Route path="/e-bicycle-insurance" element={<InsuranceScreen />} />
          <Route path="/partner-with-us" element={<PartnerScreen />} />
          {/* <Route
					path='/shipping'
					element={<ShippingScreen />}
				/> */}
          <Route path="/cart" element={<CartScreen />} />
          <Route path="/testride" element={<TestRideScreen />} />
          <Route path="/testride/youtube-ad" element={<TestRideDuplicate />} />
          <Route
            path="/testride-scheduled"
            element={<TestRideConfirmScreen />}
          />
          <Route
            path="/testride-scheduled/english"
            element={<TestRideConfirmScreen />}
          />
          <Route
            path="/testride-scheduled/malyalam"
            element={<TestRideConfirmScreen />}
          />
          {/* <Route
					path='/ordersummary'
					element={<OrderSummaryScreen />}
				/>
				<Route
					path='/ordersummary/:id'
					element={<OrderSummaryScreen />}
				/> */}
          <Route path="/thank-you" element={<OrderConfirmScreen />} />
          <Route path="/service" element={<ServiceQueryScreen />} />
          <Route path="/payment-failed" element={<PaymentFailedScreen />} />
          <Route path="/prebook-success" element={<PreOrderThankyouScreen />} />
          <Route path="/checked-in" element={<CheckInSuccessScreen />} />
          <Route
            path="/registration-confirmation"
            element={<LeadSubmitScreen />}
          />
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path="/profile/orders" element={<MyOrdersScreen />} />
          <Route path="/profile/orders/:id" element={<MyOrderDetails />} />
          <Route path="/submitted" element={<ThankYouPage />} />
          <Route path="/submitted/amazon" element={<ThankYouPage />} />
          <Route path="/submitted/flipkart" element={<ThankYouPage />} />
          <Route path="/book-submitted" element={<ThankYouPage />} />
          <Route path="/community" element={<CommunityScreen />} />
          <Route path="/terms&condition" element={<TermsAndCondition />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
          {/* <Route path="/track" element={<TrackingScreen />} /> */}
          <Route path="/accessories" element={<AllAccessoryScreen />} />
          <Route path="/accessories/xcape" element={<XcapeScreen />} />
          <Route
            path="/refund-and-cancellation-policy"
            element={<ReturnPolicyScreen />}
          />
          <Route path="/app/privacy-policy" element={<AppPrivacyPolicy />} />
          <Route
            path="/app/terms-and-conditions"
            element={<AppTermsAndConditions />}
          />
          {/*  */}
          {/*  */}
          {/* URL Redirects */}
          <Route path="/Warranty.aspx" element={<Navigate to="/warranty" />} />
          <Route path="/pages/emx" element={<Navigate to="/bikes/emx" />} />
          <Route
            path="/products/lil-e"
            element={<Navigate to="/bikes/lile" />}
          />
          <Route
            path="/pages/lil-e-landing-page"
            element={<Navigate to="/bikes/lile" />}
          />
          <Route
            path="/products/t-rexplus"
            element={<Navigate to="/bikes/t-rex+" />}
          />
          <Route
            path="/trexplus-prebook"
            element={<Navigate to="/bikes/t-rex+" />}
          />
          <Route
            path="/products/t-rex"
            element={<Navigate to="/bikes/t-rex+" />}
          />
          <Route
            path="/pages/contact-us-original"
            element={<Navigate to="/contact-us" />}
          />
          <Route
            path="/products/privacy.html"
            element={<Navigate to="/privacy-policy" />}
          />
          <Route
            path="/products/cookie.html"
            element={<Navigate to="/cookie-policy" />}
          />
          <Route path="/partner-with-us" element={<Navigate to="/partner" />} />
          <Route
            path="/Community.aspx"
            element={<Navigate to="/community" />}
          />
          <Route
            path="/contactus@emtorad.com"
            element={<Navigate to="/contact-us" />}
          />
          <Route path="/Products.aspx" element={<Navigate to="/" />} />
          <Route
            path="/products/moovway-x?variant=32606162944045"
            element={<Navigate to="/" />}
          />
          <Route path="/FAQs.aspx" element={<Navigate to="/" />} />
          <Route path="/contact-us" element={<Navigate to="/contact-us" />} />
          <Route
            path="/TermsConditions.aspx"
            element={<Navigate to="/terms&condition" />}
          />
          <Route
            path="/pages/doodle-final"
            element={<Navigate to="/bikes/doodle-v3" />}
          />
          <Route
            path="/pages/trex-final"
            element={<Navigate to="/bikes/t-rex+" />}
          />
          <Route
            path="/Insurance.aspx"
            element={<Navigate to="/e-bicycle-insurance" />}
          />
          <Route
            path="/Media/Brochures/T-REX.pdf"
            element={<Navigate to="/warranty" />}
          />
          <Route
            path="/pages/emx-final-1"
            element={<Navigate to="/bikes/emx" />}
          />
          <Route
            path="/products/emx-indias-first-dual-suspension-e-bike"
            element={<Navigate to="/bikes/emx" />}
          />
          <Route path="/pages/emi-1" element={<Navigate to="/emi" />} />
          <Route path="/pages/test-ride-form" element={<Navigate to="/" />} />
          <Route
            path="/privacy.html"
            element={<Navigate to="/privacy-policy" />}
          />
          <Route
            path="/cookie.html"
            element={<Navigate to="/cookie-policy" />}
          />
          <Route
            path="/pages/privacy.html"
            element={<Navigate to="/privacy-policy" />}
          />
          <Route
            path="/pages/cookie.html"
            element={<Navigate to="/cookie-policy" />}
          />
          <Route path="/product/Emx" element={<Navigate to="/bikes/emx" />} />
          <Route
            path="/insurance/emx"
            element={<Navigate to="/e-bicycle-insurance" />}
          />
          <Route
            path="/insurance/t-rex"
            element={<Navigate to="/e-bicycle-insurance" />}
          />
          <Route path="/pages/emi-1" element={<Navigate to="/emi" />} />
          <Route
            path="/partner-with-us/mailtocontactus@emotorad.com:"
            element={<Navigate to="/contact-us" />}
          />
          <Route path="/Cart.aspx" element={<Navigate to="/cart" />} />
          <Route path="/collections/frontpage" element={<Navigate to="/" />} />
          <Route path="/book-test-ride" element={<Navigate to="/testride" />} />
          <Route path="/AboutUs.aspx" element={<Navigate to="/ourstory" />} />
          <Route path="/product/emx" element={<Navigate to="/bikes/emx+" />} />
          <Route
            path="/allproduct/emx"
            element={<Navigate to="/bikes/emx+" />}
          />
          <Route path="/trex" element={<Navigate to="/bikes/t-rex+" />} />
          <Route path="/doodle" element={<Navigate to="/bikes/doodle-v3" />} />
          <Route path="/product" element={<Navigate to="/bikes" />} />
          <Route
            path="/product/t-rex"
            element={<Navigate to="/bikes/t-rex+" />}
          />
          <Route
            path="/product/trex"
            element={<Navigate to="/bikes/t-rex+" />}
          />
          <Route path="/product/emx" element={<Navigate to="/bikes/emx+" />} />
          {/* <Route path="/bikes" element={<Navigate to="/" />} /> */}
          {/* <Route path="/emi" element={<Navigate to="/emi" />} /> */}
          {/* <Route path="/contact" element={<Navigate to="/contact" />} /> */}
          <Route
            path="/products/trexplus"
            element={<Navigate to="/bikes/t-rex+" />}
          />
          <Route path="/allbikes" element={<Navigate to="/bikes" />} />
          <Route
            path="/all-accessories"
            element={<Navigate to="/accessories" />}
          />
          {/* <Route
            path="/bikes/doodlev3"
            element={<Navigate to="/bikes/doodlev3" />}
          /> */}
          <Route
            path="/bikes/doodle"
            element={<Navigate to="/bikes/doodle-v3" />}
          />
          <Route
            path="/find-store"
            element={<Navigate to="/find-e-bicycle-store" />}
          />
          <Route
            path="/insurance"
            element={<Navigate to="/e-bicycle-insurance" />}
          />
          {/* <Route path="/contactus" element={<Navigate to="/contactus" />} /> */}
          {/* <Route
            path="/accessories/category/spare"
            element={<Navigate to="/accessories/category/headlight" />}
          /> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
