import React, { useEffect } from "react";
import ordersuccess from "../images/order-confirm.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CART_RESET } from "../Constants/cartConstants";
import {
  ORDER_CONFIRM_RESET,
  ORDER_CONFIRM_SUCCESS,
} from "../Constants/orderConstants";
import { Helmet } from "react-helmet";

const OrderConfirmScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const orderConfirm = useSelector((state) => state.orderConfirm);
  const { orderStatus } = orderConfirm;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const status = params.get("status");
    const amount = params.get("amount");
    const txnid = params.get("txnid");

    if (status === "success") {
      dispatch({
        type: ORDER_CONFIRM_SUCCESS,
        payload: { amount, txnid },
      });
      localStorage.removeItem("cartItems");
      dispatch({
        type: CART_RESET,
      });
    }

    return () => {
      dispatch({
        type: ORDER_CONFIRM_RESET,
      });
    };
  }, [dispatch, location]);

  return (
    <>
      <Helmet>
        <title>EMotorad | Order Confirm</title>
        <script>
          {window.gtag("event", "conversion", {
            send_to: "AW-10790037225/9y-7COPowZYYEOnNi5ko",
            value: orderStatus?.amount,
            currency: "INR",
            transaction_id: orderStatus?._id,
          })}
        </script>
      </Helmet>

      <div style={{ height: "90vh" }} className="container">
        <div className="row h-100 align-items-center position-relative">
          <div className="col-12 col-lg-6 offset-lg-3">
            <img
              src={ordersuccess}
              className="img-fluid d-block w-75 mx-auto"
              alt=""
            />
            <h6
              className="text-center"
              style={{
                lineHeight: "1.5em",
                fontSize: "1.1em",
                fontWeight: "700",
              }}
            >
              We’re happy to let you know that we’ve received your order.
            </h6>

            <p
              className="mt-lg-4 mt-4 text-center"
              style={{
                lineHeight: "1.5em",
                fontSize: "0.9em",
                fontWeight: "200",
              }}
            >
              You will be receiving a confirmation email with the order details.
            </p>
          </div>

          <Link className="d-block mx-auto text-center" to="/">
            Go back
          </Link>
        </div>
      </div>
    </>
  );
};

export default OrderConfirmScreen;
