import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const FindStorePopUp = ({
  isOpen,
  onClose,
  storeName,
  storeAddress,
  locationURL,
}) => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();

  const bookRide = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const pageVisited = "find-store";
      const result = await axios.post(
        "/api/testride/bookaride-find-store",
        {
          name,
          phoneNumber,
          storeName,
          storeAddress,
          locationURL,
          pageVisited,
        },
        config
      );

      if (result.status === 200) {
        navigate("/testride-scheduled/english");
      } else {
        alert("Failed to book the test ride.");
      }
    } catch (error) {
      console.error("There was an error booking the ride:", error);
      alert("Server error. Please try again later.");
    }
  };

  if (!isOpen) return null;
  return (
    <div className="form-pop-up">
      <div className="modal-overlay">
        <div className="modal-content p-4">
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={onClose}
              style={{ height: "0.3em", width: "0.3em" }}
            ></button>
          </div>

          <div className="modal-body">
            <h4
              className="text-center mb-4"
              style={{ fontFamily: "Metropolis-medium", fontSize: "1.2em" }}
            >
              Experience Electric Fun
            </h4>
            <form onSubmit={bookRide}>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="tel"
                  className="form-control"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => {
                    const phoneNumber = e.target.value.slice(0, 12);
                    setPhoneNumber(phoneNumber);
                    if (/^[6-9]\d{9}$/.test(phoneNumber)) {
                      e.target.setCustomValidity("");
                    } else {
                      e.target.setCustomValidity(
                        "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                      );
                    }
                  }}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                    )
                  }
                  required
                />
              </div>
              <button
                type="submit"
                className="formPopUpBtn background-em py-2 text-light w-100 rounded-3"
              >
                BOOK A TEST RIDE
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindStorePopUp;
