import React, { useEffect, useState } from "react";
import { GraphQLClient } from "graphql-request";
import { gql } from "graphql-request";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import Meta from "../Components/Meta";
import TestRide from "../Components/TestRide";
const BlogScreen = () => {
  const { slug } = useParams();
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [blogPost, setBlogPost] = useState([]);
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!slug) return;
      const hygraph = new GraphQLClient(
        "https://api-ap-south-1.hygraph.com/v2/clcrn35rt0ct401ur6hv90b6q/master"
      );
      const Query1 = gql`
        query GetBlogPost($slug: String!) {
          posts(where: { slug: $slug }) {
            author {
              name
            }
            coverImage {
              url
            }
            date
            slug
            seo {
              title
              description
              keywords
            }
            tags
            title
            content {
              html
              markdown
              raw
              text
            }
          }
        }
      `;
      try {
        const { posts } = await hygraph.request(Query1, { slug });
        setBlogPost(posts[0]);
      } catch (error) {
        console.error("Error fetching blog post:", error);
      }
    };

    fetchData();
  }, [slug]);

  useEffect(() => {
    const fetchData = async () => {
      if (!slug) return;
      const hygraph = new GraphQLClient(
        "https://api-ap-south-1.hygraph.com/v2/clcrn35rt0ct401ur6hv90b6q/master"
      );
      const Query2 = gql`
        {
          posts(orderBy: date_DESC) {
            title
            slug
            coverImage {
              url
            }
            excerpt
          }
        }
      `;
      try {
        const { posts } = await hygraph.request(Query2, { slug });
        setBlogPosts(posts);
      } catch (error) {
        console.error("Error fetching blog post:", error);
      }
    };

    fetchData();
  }, [slug]);

  const renderBlogContent = () => {
    return parse(blogPost.content.html, {
      replace: (domNode) => {
        if (domNode.name && domNode.name === "img") {
          return <img {...domNode.attribs} className="img-fluid" />;
        }
        return undefined;
      },
    });
  };

  return (
    <>
      <>
        {blogPost && (
          <Meta
            title={blogPost.seo && blogPost.seo.title}
            description={blogPost.seo && blogPost.seo.description}
            cannonicalTag={`https://www.emotorad.com/blogs/${slug}`}
            keywords={blogPost.seo && blogPost.seo.keywords.join(", ")}
          />
        )}
        <Navbar breadcrumb={true} />
        <div className="container mt-lg-5 mt-4">
          {blogPost && blogPost.content && blogPosts && (
            <>
              <div>
                <h1 className=" font-mon">{blogPost.title}</h1>
                <div className="mt-3">
                  <div className="col-md-4 py-3">
                    <h6 className="mb-2">Published On</h6>
                    <p className="fw-bold  font-mon">
                      <span className="pe-2 text-muted">
                        {weekday[new Date(blogPost.date).getDay()]},{" "}
                      </span>

                      <span className="pe-2 text-muted">
                        {new Date(blogPost.date).getDate()} {""}
                        {months[new Date(blogPost.date).getMonth()]}
                      </span>
                      <span className="pe-2 text-muted">
                        {new Date(blogPost.date).getFullYear()}
                      </span>
                    </p>
                    <hr />
                    {/* <p>- {blogPost.author.name}</p> */}
                  </div>
                  <div className="">
                    <img
                      style={{
                        borderRadius: "10px",
                      }}
                      src={blogPost.coverImage && blogPost.coverImage.url}
                      className="img-fluid  d-block mx-auto"
                      alt=""
                    />
                    <div id="content" className="blog-content mt-4">
                      {renderBlogContent()}
                    </div>
                  </div>
                </div>

                <div></div>
              </div>
              <div className="mb-4">
                <hr className="mb-4 mb-lg-5"></hr>
                <h5 className="mb-3 fw-bold">
                  Other Related Articles that might interest you
                </h5>
                <div
                  className="d-flex flex-column flex-lg-row"
                  style={{ gap: "1.2em" }}
                >
                  {blogPosts.slice(0, 4).map((post, i) => (
                    <div className="related-blogs">
                      <Link
                        to={`/blogs/${post.slug}`}
                        className="text-decoration-none text-dark"
                      >
                        <img
                          src={post.coverImage.url}
                          alt=""
                          className="img-fluid rounded-2"
                        />
                        <h6 className="my-2 text-em">{post.title}</h6>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
        <Footer />
      </>
    </>
  );
};

export default BlogScreen;
