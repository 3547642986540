import axios from "axios";
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CHECK_PROMOCODE_FAIL,
  CHECK_PROMOCODE_REQUEST,
  CHECK_PROMOCODE_SUCCESS,
  CART_RESET,
} from "../Constants/cartConstants";

export const addToCart =
  (id, model, color, qty, variant) => async (dispatch, getState) => {
    const { data } = await axios.post(`/api/bikes/`, { id });

    if (data.name === "T-Rex Pro" || data.name === "Lil E") {
      model = data.variants[variant]?.models.find(
        (m) => m.modelIndex === model
      )?.modelIndex;
    } else {
      if (
        data.variants.length &&
        (data.variants[variant].models[model]
          ? model !== data.variants[variant].models[model]
          : false)
      ) {
        model = data.variants[variant].models[model];
      }
    }

    const productId = data.models[model]._id;
    dispatch({
      type: CART_ADD_ITEM,
      payload: {
        product: data._id,
        name: data.name,
        cartImages: data.cartImages,
        price:
          data.name === "T-Rex Pro" || data.name === "Lil E"
            ? data.variants[variant]?.models.find((m) => m.modelIndex === model)
                .price
            : data.name === "Photon" || data.name === "Ray Light"
            ? data.variant[variant].price
            : data.models[model].price
            ? data.models[model].price
            : data.accPrice,
        gst: data.gst,
        withoutGST: data.withoutGST,
        models: data.models,
        tagLine: data.productType,
        slashPrice:
          data.name === "T-Rex Pro" || data.name === "Lil E"
            ? data.variants[variant]?.models.find((m) => m.modelIndex === model)
                .slashPrice
            : data.models[model].slashPrice
            ? data.models[model].slashPrice
            : data.slashPrice,
        category: data.category,
        accPrice:
          data.name === "T-Rex Pro" || data.name === "Lil E"
            ? data.variants[variant]?.models.find((m) => m.modelIndex === model)
                .price
            : data.name === "Photon" || data.name === "Ray Light"
            ? data.variant[variant].price
            : data.models[model].price
            ? data.models[model].price
            : data.accPrice,
        color,
        colorName: data.models[model].colorName,
        model,
        variant:
          data.name === "Photon" || data.name === "Ray Light"
            ? data.variant.length
              ? variant
              : ""
            : data.variants.length
            ? variant
            : "",
        variants:
          data.name === "Photon" || data.name === "Ray Light"
            ? data.variant.length
              ? data.variant
              : null
            : data.variants.length
            ? data.variants
            : null,
        qty,
        productModel: productId,
      },
    });

    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().cart.cartItems)
    );
  };

export const removeFromCart =
  (id, color, name, variantType) => (dispatch, getState) => {
    dispatch({
      type: CART_REMOVE_ITEM,
      payload: {
        productModel: id,
        colorName: color,
        name: name,
        variantType: variantType,
      },
    });
    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().cart.cartItems)
    );
  };

export const resetCart =
  (id, color, name, variantType) => (dispatch, getState) => {
    dispatch({
      type: CART_RESET,
      payload: {
        productModel: id,
        colorName: color,
        name: name,
        variantType: variantType,
      },
    });

    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().cart.cartItems)
    );
  };

export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  });
  localStorage.setItem("shippingAddress", JSON.stringify(data));
};

export const getPromocodes = (promocode, cartItems) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    dispatch({ type: CHECK_PROMOCODE_REQUEST });
    const { data } = await axios.post(
      "/api/order/promocode",
      {
        promocode,
        cartItems,
      },
      config
    );
    dispatch({
      type: CHECK_PROMOCODE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHECK_PROMOCODE_FAIL,
      payload: false,
    });
  }
};
