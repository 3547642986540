import {
	CREATE_TESTRIDE_APPOINTMENT_FAIL,
	CREATE_TESTRIDE_APPOINTMENT_REQUEST,
	CREATE_TESTRIDE_APPOINTMENT_SUCCESS,
} from "../Constants/testrideConstants"

import axios from "axios"
import { useNavigate } from "react-router-dom"

export const createTestRide =
	(name, phoneNumber, city, country, zip, latitude,longitude) =>
	async (dispatch) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
				},
			}

			dispatch({
				type: CREATE_TESTRIDE_APPOINTMENT_REQUEST,
			})
			const { data } = await axios.post(
				"/api/testride/book",
				{
					name,
					phoneNumber,
					city,
					country,
					zip,
					latitude,
					longitude
				},
				config,
			)

			dispatch({
				type: CREATE_TESTRIDE_APPOINTMENT_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: CREATE_TESTRIDE_APPOINTMENT_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
